import indexStyles from '../sass/index.scss';
let commons = require('./utils/commons');
import projectAnimationJS from './includes/projects_animation.js';
import project1IMG from '../img/project-1.png';
import project2IMG from '../img/project-2.png';
import project3IMG from '../img/project-3.png';
import project4IMG from '../img/project-4.png';
import project5IMG from '../img/project-5.png';
import project6IMG from '../img/project-6.png';
import project7IMG from '../img/project-7.png';
import project8IMG from '../img/project-8.png';
import project9IMG from '../img/project-9.png';
import project10IMG from '../img/project-10.png';
import project11IMG from '../img/project-11.png';
import project12IMG from '../img/project-12.png';
import project13IMG from '../img/project-13.png';