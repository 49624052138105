(function( $ ) {

    $.fn.projectsAnimation = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqProjectListContainer = $(this);
            var jqAllElementsToAnimate = jqProjectListContainer.find('.to-animate');
            const listContainerInitialHeight = jqProjectListContainer.outerHeight();
            const listContainerInitialOffset = jqProjectListContainer.offset();
            var listContainerCalulatedEnd = listContainerInitialHeight + listContainerInitialOffset.top - 720;
            if (listContainerCalulatedEnd <= 720) {
                listContainerCalulatedEnd = 720;
            }

            //initial
            var startYoffset = window.pageYOffset;
            if (startYoffset > listContainerCalulatedEnd) {
                jqAllElementsToAnimate.addClass('animated');
            }
            
            $(window).scroll(function() {
                var currentYoffset = window.pageYOffset;
                if (currentYoffset > listContainerCalulatedEnd) {
                    jqAllElementsToAnimate.addClass('animated');
                }
            })
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.project-animated').projectsAnimation();
});
